import Vue from 'vue';
export const endpointUrl = '/api/webhooks';

export default {
  async listWebhooks(params = {}) {
    // TODO: Add pagination
    const { data } = await Vue.prototype.$harbourData.get(endpointUrl, {}, params).catch((err) => {
      throw err;
    });
    return data?.items || [];
  },

  async getWebhook(id) {
    const { data } = await Vue.prototype.$harbourData.get(`${endpointUrl}/${id}`).catch((err) => {
      throw err;
    });
    return data;
  },

  async createWebhook(payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .post(endpointUrl, payload, params)
      .catch((err) => {
        throw err;
      });
    return data;
  },

  async updateWebhook(id, payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .put(`${endpointUrl}/${id}`, payload, params)
      .catch((err) => {
        throw err;
      });
    return data;
  },

  async deleteWebhook(id, payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .delete(`${endpointUrl}/${id}`, payload, params)
      .catch((err) => {
        throw err;
      });
    return data;
  },
};
