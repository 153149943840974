<script>
import HrbrButton from '@/components/ui/HrbrButton.vue';
import HrbrSwitch from '@/components/ui/HrbrSwitch.vue';
import HrbrUrlInput from '@/components/ui/HrbrUrlInput.vue';
import { useWebhooksStore } from '@/stores/api/webhooks-store';
import { storeToRefs } from 'pinia';

export default {
  name: 'WebhooksModal',
  components: {
    HrbrButton,
    HrbrUrlInput,
    HrbrSwitch,
  },
  setup() {
    const webhooksStore = useWebhooksStore();
    const { editedWebhook } = storeToRefs(webhooksStore)
    return {
      webhooksStore,
      editedWebhook
    };
  },
  data() {
    return {
      webhookStatus: true,
      destinationUrl: null,
      notificationEmails: [],
      isDestinationUrlValid: false,
      eventTypes: Object.entries(this.webhooksStore.getAllowedEventTypes)
        .map(([type, description]) => ({
          type,
          description,
          isSelected: false
        })),
      sendSampleDataButton: {
        show: true,
        disabled: false,
        loading: false,
        text: 'Send sample data',
        iconLeft: 'fa-light fa-bolt',
        action: this.onWebhookTestClick,
      },
      closeButton: {
        show: true,
        text: 'Cancel',
        action: this.onModalClose,
      },
      saveButton: {
        show: true,
        text: 'Save',
        disabled: this.hasRequiredFields,
        styleType: 'primary',
        action: this.onWebhookSaveClick,
      },
      atLeastOneEventSelected: false,
    };
  },
  created() {
    if (this.editedWebhook) {
      this.destinationUrl = this.editedWebhook.destination_url;
      this.notificationEmails = this.editedWebhook.notification_emails;
      this.editedWebhook.event_types.forEach(eventType => {
        this.eventTypes.find(event => event.type === eventType).isSelected = true;
      });
      this.atLeastOneEventSelected = this.editedWebhook.event_types.length > 0;
      this.webhookStatus = this.editedWebhook.status;
    }
  },
  computed: {
    hasRequiredFields() {
      return !this.isDestinationUrlValid || !this.atLeastOneEventSelected;
    },
  },
  methods: {
    onEventSwitchChange(eventType) {
      eventType.isSelected = !eventType.isSelected;
      this.atLeastOneEventSelected = this.eventTypes.some(eventType => eventType.isSelected);
    },
    onStatusSwitchChange() {
      this.webhookStatus = !this.webhookStatus;
    },
    onWebhookTestClick() {
      console.log('onWebhookTestClick');
    },
    onWebhookSaveClick() {
      this.sendSampleDataButton.loading = true;
      if (this.editedWebhook) {
        this.webhooksStore.editWebhook(this.editedWebhook.id, {
          destination_url: this.destinationUrl,
          notification_emails: this.notificationEmails,
          event_types: this.eventTypes.filter(eventType => eventType.isSelected)
            .map(eventType => eventType.type),
          status: this.webhookStatus,
        });
      } else {
        this.webhooksStore.addWebhook({
          destination_url: this.destinationUrl,
          notification_emails: this.notificationEmails,
          event_types: this.eventTypes.filter(eventType => eventType.isSelected)
            .map(eventType => eventType.type),
        });
      }
      this.sendSampleDataButton.loading = false;
      this.onModalClose();
    },
    onModalClose() {
      this.editedWebhook = null;
      this.$parent.close();
    },
  },
};
</script>

<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <p v-if="!editedWebhook" class="modal-title">New Webhook</p>
      <p v-else class="modal-title">Edit Webhook</p>

      <div class="container">
        <span>Send POST JSON event data to:</span>
        <div class="destination-url-controls">
          <div class="label">
            Destination URL
          </div>

          <HrbrUrlInput
            v-model.trim="destinationUrl"
            :icon-pack="'fa'"
            :icon="'regular fa-pen'"
            step="any"
            expanded
            @isValidUrlChanged="isDestinationUrlValid = $event"
          />
        </div>

        <span>Receive notification when webhook fails:</span>
        <div class="notification-emails-controls">
          <span class="label">Notification email(s)</span>
          <div class="autocomplete-container">
            <EmailInput
              v-model="notificationEmails"
              placeholder="enter emails"
              icon-pack="fas"
              icon="envelope"
              ellipsis
              :maxlength="500"
              :has-counter="false"
              maxtags="50"
              :readonly="false"
              :on-paste-separators="[',', ' ']"
              :email-store="notificationEmails"
              :results-limit="30"
              :recents-tags-limit="5"
              :show-self-in-recents="false"
            />
          </div>
        </div>

        <span>Trigger the sending of data on:</span>
        <div class="triggers-controls">
          <div v-for="eventType in eventTypes" v-bind:key="eventType.type" class="trigger-container">
            <span class="event-type-text">{{ eventType.description }}</span>
            <HrbrSwitch
              :toggled-on="eventType.isSelected"
              class="switch-aligned-right"
              @click="onEventSwitchChange(eventType)"
            />
          </div>
        </div>

        <span v-if="editedWebhook">Status of the webhook:</span>
        <div v-if="editedWebhook">
          <HrbrSwitch
            :toggled-on="webhookStatus"
            @click="onStatusSwitchChange"
          />
          <div class="status-text">
            {{ webhookStatus ? 'Active' : 'Inactive' }}
          </div>
        </div>
        <span>Test setup:</span>
        <div>
          <HrbrButton id="button-send-sample-data" :button="sendSampleDataButton" type="button" />
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <HrbrButton
        :button="closeButton"
      />
      <HrbrButton
        :button="saveButton"
      />
    </footer>
  </div>
</template>

<style lang="postcss" scoped>
.modal-card-body {
  margin: 0 auto !important;
  max-width: 640px;
  width: 100%;
  background-color: white;
  border-radius: 8px 8px 0 0;
  padding: 32px;
}

.modal-title {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 22px;
}

.modal-card-foot {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  border-top: 1px solid #DBDBDB;
  background-color: white;
  padding: 16px 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.container {
  display: grid;
  grid-template-columns: 210px auto;
  grid-auto-rows: auto;
  column-gap: 10px;
  row-gap: 20px;
  align-items: flex-start;
  justify-items: stretch;

  color: #555;
  /* Body Text */
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.status-text {
  font-size: 12px;
  font-weight: 400;
  color: #333;
  margin-left: auto;
}

.label {
  margin-bottom: 5px;
  font-weight: 600;
  color: #333;
}

.event-type-text {
  font-size: 14px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 24px;
  white-space: nowrap;
  border-radius: 20px;
  padding: 2px 10px;
  user-select: none;
  background-color: #f4f4f4;
}

.triggers-controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: auto
}

.trigger-container {
  display: flex;
  flex-direction: row;
}

.switch-aligned-right {
  margin-left: auto;
}

#button-send-sample-data >>> button {
  width: 100%;
}

.autocomplete-container {
  >>> .taginput-container {
    padding: 5.5px 0;
    color: #49525c;
    border-radius: 5px;
    border-color: #dadada;
    box-shadow: none;
    &:hover {
      border-color: rgba(47, 90, 137, 0.6);
    }
    &.is-focused {
      box-shadow: none;
      border-color: rgba(47, 90, 137, 0.6);
    }
    input {
      margin-bottom: 0;
      font-size: 14px;
      color: #49525c;
    }
    .icon.is-left {
      font-size: 15px;
      color: #49525c;
    }
    > .tag:has(+ .control) {
      margin-bottom: 0;
    }
  }
  >>> .dropdown-menu {
    padding-top: 2px;
  }
  >>> .dropdown-content {
    max-height: 240px;
    padding: 8px 0;
    border-radius: 8px;
    border: none;
    box-shadow: 0 4px 12px 0 #00000026;
    .dropdown-item {
      padding: 12px 18px;
      color: #333;
      font-size: 12px;
      img, .email-input-initials {
        width: 26px !important;
        height: 26px !important;
        background-color: #D1DBF4 !important;
        flex-shrink: 0;
      }
    }
    .user-name {
      font-size: 14px;
      font-weight: 600;
      opacity: 1 !important;
    }
    .user-data {
      display: flex;
      flex-direction: column;
    }
    .delete-email-option-btn {
      margin-right: 0;
      .icon {
        font-size: 14px;
        color: #333;
      }
    }
  }
  >>> .vue-email-input .recent-emails .tag {
    border-radius: 20px;
  }
}
</style>
