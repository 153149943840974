import webhooksApiService from '@/services/api/webhooks-api-service';
import { useHarbourStore } from '@/stores/harbour-store';
import { defineStore } from 'pinia';

export const useWebhooksStore = defineStore('webhooks-store', {
  state: () => ({
    harbourStore: useHarbourStore(),
    webhooks: [],
    isLoading: false,
    isSkeletonLoading: false,
    gridApi: null,
    gridColumnApi: null,
    editedWebhook: null,
    eventsTypeMap: {
      'agreement.viewed': 'Viewed agreement',
      'agreement.signed': 'Incrementally signed agreement',
      'agreement.completed': 'Completed agreement',
      'agreement.rejected': 'Rejected agreement',
    },
    orgsWithVerificationFeature: [
      'ORG-shiftsmart-85159b38731b44afa4cc55367c1d3450',
      'ORG13123134123123212',
      'ORG-livetest-c9619cec30ed4e26808b51bfb8bdf62c',
    ],
  }),
  getters: {
    getAllowedEventTypes: (state) => {
      // TODO: implement feature flag
      if (
        state.orgsWithVerificationFeature.includes(state.harbourStore.contextDict.organizationid)
      ) {
        return {
          ...state.eventsTypeMap,
          verification: 'Verification',
        };
      }
      return state.eventsTypeMap;
    },
    getWebhookById: (state) => (id) => {
      if (!id) return {};
      return state.webhooks.find((item) => item.id === id);
    },
  },
  actions: {
    getParams(params) {
      // NOTE: adding user context to the params
      // * This is a temporary solution until context is accessed by authenticated user in the backend
      return {
        ...params,
        organization_id: this.harbourStore.contextDict.organizationid,
      };
    },
    getPayload(payload, is_update = false) {
      // NOTE: adding user context to the payload
      // * This is a temporary solution until context is accessed by authenticated user in the backend
      const data = {
        ...payload,
        organization_id: this.harbourStore.contextDict.organizationid,
      };
      if (is_update) {
        data.updated_by = this.harbourStore.contextDict.systemuserid;
      } else {
        data.created_by = this.harbourStore.contextDict.systemuserid;
      }
      return data;
    },
    async fetchWebhooks(params = {}) {
      this.isSkeletonLoading = true;
      this.webhooks = await webhooksApiService.listWebhooks(this.getParams(params));
      this.isSkeletonLoading = false;
    },
    async addWebhook(payload) {
      this.isLoading = true;
      try {
        const res = await webhooksApiService.createWebhook(this.getPayload(payload));
        this.webhooks.push(res);
      } finally {
        this.isLoading = false;
      }
    },
    async editWebhook(id, payload) {
      this.isLoading = true;
      try {
        const res = await webhooksApiService.updateWebhook(id, this.getPayload(payload, true));
        const index = this.webhooks.findIndex((item) => item.id === id);
        this.webhooks.splice(index, 1, res);
      } finally {
        this.isLoading = false;
      }
    },
    async removeWebhook(id, payload = {}) {
      this.isLoading = true;
      try {
        await webhooksApiService.deleteWebhook(id, this.getPayload(payload, true));
        this.webhooks = this.webhooks.filter((item) => item.id !== id);
      } finally {
        this.isLoading = false;
      }
    },
    resetGridApi() {
      this.gridApi = null;
      this.gridColumnApi = null;
    },
  },
});
