import Vue from 'vue';
export const endpointUrl = '/api/api-keys';

export default {
  async listApiKeys(params = {}) {
    // TODO: Add pagination
    const { data } = await Vue.prototype.$harbourData.get(endpointUrl, {}, params).catch((err) => {
      throw err;
    });
    return data;
  },

  async getApiKey(id) {
    const { data } = await Vue.prototype.$harbourData.get(`${endpointUrl}/${id}`).catch((err) => {
      throw err;
    });
    return data;
  },

  async createApiKey(payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .post(endpointUrl, payload, params)
      .catch((err) => {
        throw err;
      });
    return data;
  },

  async updateApiKey(id, payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .put(`${endpointUrl}/${id}`, payload, params)
      .catch((err) => {
        throw err;
      });
    return data;
  },

  async deleteApiKey(id, payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .delete(`${endpointUrl}/${id}`, payload, params)
      .catch((err) => {
        throw err;
      });
    return data;
  },
};
