import apiKeysApiService from '@/services/api/api-keys-api-service';
import { useHarbourStore } from '@/stores/harbour-store';
import { defineStore } from 'pinia';

export const useApiKeysStore = defineStore('api-keys-store', {
  state: () => ({
    harbourStore: useHarbourStore(),
    apiKeys: [],
    isLoading: false,
    isSkeletonLoading: false,
    gridApi: null,
    gridColumnApi: null,
  }),
  actions: {
    getParams(params) {
      // NOTE: adding user context to the params
      // * This is a temporary solution until context is accessed by authenticated user in the backend
      return {
        ...params,
        organization_id: this.harbourStore.contextDict.organizationid,
      };
    },
    getPayload(payload, is_update = false) {
      // NOTE: adding user context to the payload
      // * This is a temporary solution until context is accessed by authenticated user in the backend
      const data = {
        ...payload,
        organization_id: this.harbourStore.contextDict.organizationid,
      };
      if (is_update) {
        data.updated_by = this.harbourStore.contextDict.systemuserid;
      } else {
        data.created_by = this.harbourStore.contextDict.systemuserid;
      }
      return data;
    },
    async fetchApiKeys(params = {}) {
      this.isSkeletonLoading = true;
      try {
        const res = await apiKeysApiService.listApiKeys(this.getParams(params));
        this.apiKeys = res?.items || [];
        return res;
      } finally {
        this.isSkeletonLoading = false;
      }
    },
    async addApiKey(payload) {
      this.isLoading = true;
      try {
        const res = await apiKeysApiService.createApiKey(this.getPayload(payload));
        this.apiKeys.push(res);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    async editApiKey(id, payload) {
      this.isLoading = true;
      try {
        const res = await apiKeysApiService.updateApiKey(id, this.getPayload(payload, true));
        const index = this.apiKeys.findIndex((item) => item.id === id);
        this.apiKeys.splice(index, 1, res);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    async removeApiKey(id, payload = {}) {
      this.isLoading = true;
      try {
        const res = await apiKeysApiService.deleteApiKey(id, this.getPayload(payload, true));
        this.apiKeys = this.apiKeys.filter((item) => item.id !== id);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    resetGridApi() {
      this.gridApi = null;
      this.gridColumnApi = null;
    },
  },
});
